<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            :tab-index="tabIndex"
            headerLogo="logotypes/storage_36.svg"
            headerText="Setup Your Process"
            @continue="continueForm"
            @reset="resetForm"
            ref="tabbedForm"
            details
            :dirty="dirty"
            :fetched="fetched"
            :update="updateProcess"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-form="{step}">
                <b-form-group label="Name" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.processForm.name.$model"
                                type="text"
                                placeholder="Enter Process name"
                                :id="`${formId}-name`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('processForm.name', step)"
                                invalid="This field is required"
                                valid="Process name is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Select Source" :label-cols="2" :horizontal="true">
                    <b-row class="push-down-form-row">
                        <b-col md="8">
                            <b-form-radio-group
                                v-model="source"
                                :options="sourceOptions"
                                :id="`${formId}-source`"
                            ></b-form-radio-group>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    v-if="source === 'Query Job'"
                    label="Query Job"
                    :label-cols="2"
                    :horizontal="true"
                >
                    <b-row>
                        <b-col md="8">
                            <wit-select
                                v-model="$v.queryJobSource.queryJob.$model"
                                label="name"
                                placeholder="Select Query Job"
                                :options="queryJobs"
                                :id="`${formId}-queryjob`"
                            ></wit-select>
                            <Feedback
                                :state="validateRef('queryJobSource.queryJob', step)"
                                invalid="This field is required"
                                valid="Query Job is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <div v-else>
                    <div v-if="process.source.tableSource">
                        <b-form-group
                            label="Current Google Cloud Project"
                            :label-cols="2"
                            :horizontal="true"
                        >
                            <b-row>
                                <b-col md="8">
                                    <wit-select
                                        :options="[process.source.tableSource.projectId]"
                                        v-model="process.source.tableSource.projectId"
                                        disabled
                                    ></wit-select>
                                </b-col>
                            </b-row>
                        </b-form-group>

                        <b-form-group label="Current Dataset" :label-cols="2" :horizontal="true">
                            <b-row>
                                <b-col md="8">
                                    <wit-select
                                        :options="[process.source.tableSource.datasetId]"
                                        v-model="process.source.tableSource.datasetId"
                                        disabled
                                    ></wit-select>
                                </b-col>
                            </b-row>
                        </b-form-group>

                        <b-form-group label="Current Table" :label-cols="2" :horizontal="true">
                            <b-row>
                                <b-col md="8">
                                    <wit-select
                                        :options="[process.source.tableSource.tableId]"
                                        v-model="process.source.tableSource.tableId"
                                        disabled
                                    ></wit-select>
                                </b-col>
                            </b-row>
                        </b-form-group>

                        <b-form-group :label-cols="2" :horizontal="true">
                            <b-row>
                                <b-col md="8">
                                    <b-button
                                        variant="primary"
                                        @click="() => (changeTableSource = true)"
                                    >Change</b-button>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </div>

                    <div v-if="changeTableSource">
                        <ChooseBQTable
                            v-model="$v.bigQuerySource.$model"
                            :needsAuth="true"
                            depth="table"
                        />
                    </div>
                </div>

                <p
                    :class="{
                        'm-1': true,
                        'collapse-section-button': true,
                        'arrow-right': optionsShown,
                        'arrow-down': !optionsShown,
                    }"
                    @click="optionsShown = !optionsShown"
                >Advanced options</p>
                <b-collapse v-model="optionsShown">
                    <b-form-group label="Output Format" :label-cols="2" :horizontal="true">
                        <b-row class="push-down-form-row">
                            <b-col md="8">
                                <b-form-radio-group
                                    v-model="$v.optionsForm.outputFormat.$model"
                                    :options="outputFormatOptions"
                                ></b-form-radio-group>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <b-form-group label="Use Compression" :label-cols="2" :horizontal="true">
                        <b-row>
                            <b-col md="8">
                                <b-form-checkbox-group class="push-down-form-row">
                                    <label
                                        class="switch switch-label switch-pill switch-primary switch-show-password"
                                        data-children-count="1"
                                    >
                                        <input
                                            class="switch-input"
                                            type="checkbox"
                                            v-model="$v.optionsForm.useCompression.$model"
                                        />
                                        <span
                                            class="switch-slider"
                                            data-checked="On"
                                            data-unchecked="Off"
                                        ></span>
                                    </label>
                                </b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group label="Make file public" :label-cols="2" :horizontal="true">
                        <b-row>
                            <b-col md="8">
                                <b-form-checkbox-group class="push-down-form-row">
                                    <label
                                        class="switch switch-label switch-pill switch-primary switch-show-password"
                                        data-children-count="1"
                                    >
                                        <input
                                            class="switch-input"
                                            type="checkbox"
                                            v-model="$v.optionsForm.public.$model"
                                        />
                                        <span
                                            class="switch-slider"
                                            data-checked="On"
                                            data-unchecked="Off"
                                        ></span>
                                    </label>
                                </b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group label="Headers" :label-cols="2" :horizontal="true">
                        <b-row>
                            <b-col md="8">
                                <b-form-checkbox-group class="push-down-form-row">
                                    <label
                                        class="switch switch-label switch-pill switch-primary switch-show-password"
                                        data-children-count="1"
                                    >
                                        <input
                                            class="switch-input"
                                            type="checkbox"
                                            v-model="$v.optionsForm.printHeader.$model"
                                        />
                                        <span
                                            class="switch-slider"
                                            data-checked="On"
                                            data-unchecked="Off"
                                        ></span>
                                    </label>
                                </b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-collapse>
            </template>
            <template v-slot:step-2-form="{step}">
                <b-form-group label="Processed file URL" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <URL v-model="fileUrl" />
                        </b-col>
                    </b-row>
                </b-form-group>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'

import ChooseBQTable from '@/components/ChooseBQTable.vue'
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import URL from '@/components/URL.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {formMixin} from '@/mixins/formMixin'
import {processMixin} from '@/mixins/processMixin'
import {filterDirty} from '@/shared/filterDirty.js'

export default {
    components: {
        ChooseBQTable,
        Feedback,
        Loading,
        URL,
        WitTabbedForm,
    },
    data() {
        return {
            tabIndex: 1,
            process: null,
            formId: 'save-to-storage-update-form',
            cannotContinue: true,
            processForm: {
                name: '',
            },
            currentProcessForm: {},
            source: 'Query Job',
            currentSource: '',
            sourceOptions: ['Query Job', 'BigQuery'],
            bigQuerySource: {
                project: null,
                dataset: null,
                table: null,
            },
            changeTableSource: false,
            queryJobSource: {
                queryJob: null,
            },
            currentQueryJobSource: {},
            optionsForm: {
                outputFormat: 'CSV',
                useCompression: false,
                public: false,
                printHeader: true,
            },
            currentOptionsForm: {},
            outputFormatOptions: ['CSV', 'AVRO'],
            optionsShown: false,
            processError: {
                state: null,
                message: null,
            },
        }
    },
    mixins: [formMixin, processMixin, validationMixin],
    validations: {
        processForm: {
            name: {required},
        },
        optionsForm: {
            outputFormat: {required},
            useCompression: {required},
            public: {required},
            printHeader: {required},
        },
        queryJobSource: {
            queryJob: {
                required: requiredIf(function() {
                    return this.source === 'Query Job'
                }),
            },
        },
        bigQuerySource: {
            project: {
                required: requiredIf(function() {
                    return this.source === 'BigQuery' && this.changeTableSource
                }),
            },
            dataset: {
                required: requiredIf(function() {
                    return this.source === 'BigQuery' && this.changeTableSource
                }),
            },
            table: {
                required: requiredIf(function() {
                    return this.source === 'BigQuery' && this.changeTableSource
                }),
            },
        },
    },
    computed: {
        ...mapGetters({
            pipelines: 'pipeline/pipelines',
            queryJobs: 'report/queryJobs',
            activeProject: 'project/active',
        }),
        steps() {
            return [
                {
                    name: 'Process Settings',
                    invalid: this.$v.$invalid,
                },
                {
                    name: 'File Details',
                    invalid: false,
                },
            ]
        },
        dirty() {
            const processFormDirty = filterDirty(this.processForm, this.currentProcessForm)
            const optionsFormDirty = filterDirty(this.optionsForm, this.currentOptionsForm)
            const sourceDirty = filterDirty({source: this.source}, {source: this.currentSource})
            const queryJobSourceDirty =
                !sourceDirty.source && this.source === 'Query Job'
                    ? filterDirty(this.queryJobSource, this.currentQueryJobSource)
                    : {}
            const bigQuerySourceDirty =
                !sourceDirty.source && this.source === 'BigQuery' && this.changeTableSource
                    ? {...this.bigQuerySource}
                    : {}

            return (
                Object.keys({
                    ...processFormDirty,
                    ...optionsFormDirty,
                    ...sourceDirty,
                    ...queryJobSourceDirty,
                    ...bigQuerySourceDirty,
                }).length > 0
            )
        },
        fileUrl() {
            return `https://console.cloud.google.com/storage/browser/witcloud-st-${
                this.activeProject ? this.activeProject.id.toLowerCase() : ''
            }/save-to-storage-${this.process ? this.process.id : ''}?forceOnBucketsSortingFiltering=false&project=${
                this.activeProject ? this.activeProject.projectId : ''
            }`
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Fetching Your Process...`)
        this.$store.dispatch('google/init')
        await this.$store.dispatch('report/fetchQueryJobs', {projectId: this.activeProject.id})
        await this.fetchProcess()
        this.fetched = true
        this.$store.commit('loading/PROCESSED')
    },
    methods: {
        async fetchProcess() {
            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/process/saveToStorage/${this.$route.params.id}`
            )

            this.process = response.data.data

            this.processForm = {
                name: this.process.name,
            }
            this.optionsForm = {...this.process.options}
            if (this.process.source.queryJobSource) {
                this.source = 'Query Job'
                this.queryJobSource = {
                    queryJob: this.queryJobs.find(el => el.id === this.process.source.queryJobSource.queryJobRef.id),
                }
                this.changeTableSource = true
            }
            if (this.process.source.tableSource) {
                this.source = 'BigQuery'
            }

            this.currentProcessForm = {...this.processForm}
            this.currentOptionsForm = {...this.optionsForm}
            this.currentSource = this.source
            this.currentQueryJobSource = {...this.queryJobSource}
        },

        updateProcess() {
            this.$forceUpdate()
            if (!this.$v.$invalid) {
                const processFormDirty = filterDirty(this.processForm, this.currentProcessForm)
                const optionsFormDirty = filterDirty(this.optionsForm, this.currentOptionsForm)
                const sourceDirty = filterDirty({source: this.source}, {source: this.currentSource})
                const queryJobSourceDirty =
                    !sourceDirty.source && this.source === 'Query Job'
                        ? filterDirty(this.queryJobSource, this.currentQueryJobSource)
                        : {}
                const bigQuerySourceDirty =
                    !sourceDirty.source && this.source === 'BigQuery' && this.changeTableSource
                        ? {...this.bigQuerySource}
                        : {}

                if (
                    Object.keys({
                        ...processFormDirty,
                        ...optionsFormDirty,
                        ...sourceDirty,
                        ...queryJobSourceDirty,
                        ...bigQuerySourceDirty,
                    }).length > 0
                ) {
                    let data = {
                        ...processFormDirty,
                    }

                    if (Object.keys(optionsFormDirty).length > 0) {
                        data.options = {...this.optionsForm}
                    }

                    if (sourceDirty.source === 'Query Job' || Object.keys(queryJobSourceDirty).length > 0) {
                        data.source = {
                            queryJobId: this.queryJobSource.queryJob.id,
                        }
                    }

                    if (sourceDirty.source === 'BigQuery' || Object.keys(bigQuerySourceDirty).length > 0) {
                        data.source = {
                            tableSource: {
                                projectId: this.bigQuerySource.project,
                                datasetId: this.bigQuerySource.dataset,
                                tableId: this.bigQuerySource.table,
                            },
                        }
                    }

                    this.$store.commit('loading/PROCESSING', `Updating Your Process...`)
                    this.axios
                        .patch(
                            `${process.env.VUE_APP_NODE_API_HOST}/process/saveToStorage/${this.$route.params.id}`,
                            data
                        )
                        .then(response => {
                            this.$store.commit('loading/PROCESSED')
                            this.$projectRouter.push(this.redirectUrl)
                        })
                        .catch(exception => {
                            this.$store.commit('loading/PROCESSED')
                            this.processError.state = false
                            this.processError.message = exception.response.data.data
                            this.$errorHandler.report(exception, 'Could not update form')
                        })
                } else {
                    this.$projectRouter.push('/process')
                }
            }
        },
    },
}
</script>

<style lang="scss">
.push-down-form-row {
    margin-top: 5px;
}

.make-sure-col {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.7rem;
    opacity: 0.8;
}
</style>
